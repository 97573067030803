import React from "react";
import {
  HomeContainer,
  HomeBackground,
  HomeTitle,
  SectionsContainer,
  LinkR,
  LinkContainer,
  ArrowBeer,
  TitleDecoration,
  Popup,
  PopupButton,
  PopupImage,
  PopupContainer,
} from "./HomeElements";
import { IoMdClose } from "react-icons/io";

const Home = ({ menuSections, toggle, setToggle }) => {

  return (
    <>
      <HomeContainer>
          <Popup toggle={toggle}>
            <PopupContainer>
              <PopupButton
                onClick={() => {
                  setToggle(false);
                }}
              >
                <IoMdClose />
              </PopupButton>
              <PopupImage>
                  <img
                    src={require("../images/popup.jpg")}
                    alt="promotion"
                  />
              </PopupImage>
            </PopupContainer>
          </Popup>
        <HomeBackground />
        <HomeTitle onClick={() => console.log(document.cookie)}>
          Menu digital
        </HomeTitle>
        <SectionsContainer>
          {menuSections.map((item, index) => (
            <LinkContainer key={index} special={item.special}>
              <LinkR to={`${item.route}`}>
                <h2>{item.name}</h2>
                {item.burger && <TitleDecoration />}
                {item.beer && <ArrowBeer />}
              </LinkR>
            </LinkContainer>
          ))}
        </SectionsContainer>
      </HomeContainer>
    </>
  );
};

export default Home;
