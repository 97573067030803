import React from "react";
import { ReturnButton, ImageWrapper } from "./SectionElements";
import { BiLeftArrowAlt } from "react-icons/bi";

const Section = ({ category }) => {
  let imageHash = Date.now();

  return (
    <>
      <ReturnButton to="/">
        <BiLeftArrowAlt />
      </ReturnButton>
      <ImageWrapper>
        <img src={`${category}?${imageHash}`} alt="menu" />
      </ImageWrapper>
    </>
  );
};

export default Section;
