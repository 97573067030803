import styled from "styled-components";
import imagebackground from "../images/imagebackground.jpg";
import flechabirra from "../images/flechabirra.png";
import decoraciones from "../images/decoraciones.png";
import { Link } from "react-router-dom";

export const HomeContainer = styled.div`
  position: absolute;
  z-index: -2;
  height: 100vh;
  width: 100vw;
  background: #262626;
  text-align: center;

  @media all and (orientation: landscape) {
    height: 100vw;
  }
`;

export const HomeBackground = styled.div`
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-image: url(${imagebackground});
  background-size: cover;
  background-position: center;

  @media all and (orientation: landscape) {
    height: 100vw;
  }
`;

export const ArrowBeer = styled.div`
  position: absolute;
  right: -90px;
  top: -20px;
  width: 80px;
  height: 80px;

  background-image: url(${flechabirra});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const TitleDecoration = styled.div`
  position: absolute;
  left: -25px;
  top: -25px;
  width: 40px;
  height: 40px;

  background-image: url(${decoraciones});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const HomeTitle = styled.h1`
  font-size: 2.5rem;
  color: #fecb37;
  text-transform: uppercase;
  padding-top: 20px;
  font-weight: normal;
`;

export const SectionsContainer = styled.div`
  margin: 20px 0px;
`;

export const LinkR = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    color: white;
    font-size: 1.75rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 40px;
    font-weight: normal;
  }
`;

export const LinkContainer = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  background-color: ${({ special }) => (special ? "#565656" : "#000")}};
  border: 1.5px solid #000;
  border-radius: 5px;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: #fecb37;
    transform: translate(6px, 6px);
  }
`;

export const Popup = styled.div`
  display: ${({ toggle }) => (toggle ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
`;

export const PopupContainer = styled.div`
  height: 80%;
  width: 80%;
  border-radius: 10px;
  position: absolute;
`;

export const PopupImage = styled.div`
  height: 100%;
  img {
    border-radius: 7.5px;
    width: 100%;
    height: 100%;
  }
`;

export const PopupButton = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  top: 20px;
  right: 20px;
  font-size: 30px;

  svg {
    color: white;
  }
`;
