import { Link } from "react-router-dom";
import styled from "styled-components";

export const ImageWrapper = styled.div`
position: relative;
width: 100vw;
line-height: 0px;

img{
    width: 100%;
}
`

export const ReturnButton = styled(Link)`
position: fixed;
height: 30px;
width: 30px;
border-radius: 20px;
background: rgba(0,0,0,0.5);
z-index: 10;
top: 20px;
left: 20px;
font-size: 30px;

svg{
    color: white;
}
`